import { BeadPattern as BeadPatternModel } from '../model/bead_pattern';
import { Peg, pegboards } from '../model/pegboard';

import {
  overviewPath,
  useEditPathParams,
  viewPathForItem,
} from '../common/routes/routes';

import { logEvent } from 'firebase/analytics';
import { Box, Button, CheckBox, Select } from 'grommet';
import { throttle } from 'lodash';
import {
  MouseEvent as ReactMouseEvent,
  Ref,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BsEyedropper } from 'react-icons/bs';
import {
  RiAnticlockwiseLine,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBrush2Line,
  RiClockwiseLine,
  RiEraserLine,
  RiPaintLine,
} from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useAnalytics, useUser } from 'reactfire';
import { BeadSvg } from '../bead_pattern/bead';
import BeadPattern from '../bead_pattern/bead_pattern';
import ApplicationContext from '../common/application/context';
import EditBuffer from '../common/edit_buffer/edit_buffer';
import { RenderType } from '../common/svg_image_renderer/svg_img_renderer';
import TagSelect from '../common/tag/tag_select';
import BeadPatternItemService from '../item_service/bead_pattern_item_service';
import { Bead as BeadModel, filterBeads, sortedBeads } from '../model/bead';
import { Manufacturer } from '../model/manufacturer';
import PegboardSelectItem from '../pegboard/pegboard_select_item';
import styles from './styles.module.scss';
import { useBeadPatternRenderingOptions } from '../preferences/preferences';

interface EditProps {
  beadPattern: BeadPatternModel;
}

enum Tool {
  Brush,
  Eraser,
  Dropper,
  Fill,
}

function Edit(props: EditProps) {
  const renderingOptions = useBeadPatternRenderingOptions({
    interactive: true,
  });
  const toolRenderingOptions = useBeadPatternRenderingOptions({});

  const [editBuffer, setEditBuffer] = useState(() =>
    EditBuffer.single(props.beadPattern)
  );

  const [bead, setBead] = useState<BeadModel>(
    filterBeads(
      sortedBeads,
      Manufacturer.h,
      editBuffer.current.pegboard.beadDiameter
    )[0]
  );
  const [cursorSize, setCursorSize] = useState<number>(0);

  const [tool, setTool] = useState(Tool.Brush);

  const [lastTouchEventMillis, setLastTouchEventMillis] = useState<number>();

  const navigate = useNavigate();
  const { data: user } = useUser();

  const { t } = useTranslation();
  const analytics = useAnalytics();
  const application = useContext(ApplicationContext);
  const itemService = application.itemService as BeadPatternItemService;

  const eventData = {
    bead: bead?.id,
    tool: tool,
    pegboard: editBuffer.current.pegboard.id,
  };

  const updateBeadPattern = useCallback(
    (fn: (currentBeadPattern: BeadPatternModel) => BeadPatternModel) => {
      setEditBuffer((buffer) => buffer.maybeAdd(fn(buffer.current)));
    },
    []
  );

  function onTouchEvent() {
    setLastTouchEventMillis(Date.now);
  }

  function updateCursor(event: ReactMouseEvent) {
    if (lastTouchEventMillis && Date.now() - lastTouchEventMillis < 5000) {
      return;
    }
    cursorRef.current!.setVisible(true);
    cursorRef.current!.setPosition([
      event.pageX - window.scrollX - cursorSize / 2,
      event.pageY - window.scrollY - cursorSize / 2,
    ]);
  }

  function hideCursor() {
    cursorRef.current!.setVisible(false);
    cursorRef.current!.setPosition(undefined);
  }

  function cancel() {
    navigate(
      editBuffer.current.id
        ? viewPathForItem(editBuffer.current.id)
        : overviewPath
    );
  }

  async function save() {
    const savedItem = await itemService.saveItem(editBuffer.current, user!);
    navigate(viewPathForItem(savedItem));
  }

  const beadPatternRef = useRef<HTMLDivElement>(null);
  // use useCallback so that the bead pattern can be properly memoized
  const applyToolOnPeg = useCallback(
    (isClick: boolean, peg: Peg | number, pegBead?: BeadModel) => {
      if (tool === Tool.Brush || tool === Tool.Eraser) {
        const beadToPaint = tool === Tool.Brush ? bead : undefined;
        updateBeadPattern((pattern: BeadPatternModel) => {
          const p = peg instanceof Peg ? peg : pattern.pegboard.pegs[peg];
          let m = pattern.setBead(p, beadToPaint);
          return m;
        });
      } else if (tool === Tool.Fill && isClick) {
        updateBeadPattern((pattern: BeadPatternModel) => {
          const p = peg instanceof Peg ? peg : pattern.pegboard.pegs[peg];
          let m = pattern.fill(p, bead);
          return m;
        });
      } else if (tool === Tool.Dropper && isClick) {
        if (pegBead) {
          setBead(pegBead);
        }
      }
    },
    [bead, tool, updateBeadPattern]
  );

  useEffect(() => {
    const updateCursorSize = () => {
      const cursorSize =
        bead.diameter *
        ((beadPatternRef.current?.getBoundingClientRect()?.width ?? 200) /
          editBuffer.current.pegboard.width);
      setCursorSize(cursorSize);
    };
    updateCursorSize();
    window.addEventListener('resize', updateCursorSize);
    return () => {
      window.removeEventListener('resize', updateCursorSize);
    };
  }, [beadPatternRef, bead, editBuffer]);

  useEffect(() => {
    const applyMoveOnTarget = (t: Element | null) => {
      let target = t;
      let pegIx = undefined;
      while (target) {
        if (target.attributes.getNamedItem('data-peg-ix')) {
          pegIx = parseInt(
            target.attributes.getNamedItem('data-peg-ix')!.value
          );
          break;
        }
        target = target.parentElement;
      }
      if (pegIx) {
        applyToolOnPeg(false, pegIx);
      }
    };

    const onMouseMove = throttle((event: MouseEvent) => {
      if (!event.buttons) {
        return;
      }
      let target = window.document.elementFromPoint(
        event.clientX,
        event.clientY
      );
      applyMoveOnTarget(target);
    }, 20);
    window.document.addEventListener('mousemove', onMouseMove);

    const onTouchMove = throttle((event: TouchEvent) => {
      let target = window.document.elementFromPoint(
        event.touches[0].clientX,
        event.touches[0].clientY
      );
      applyMoveOnTarget(target);
    }, 20);

    window.document.addEventListener('touchmove', onTouchMove);

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === 'p') {
        setTool(Tool.Dropper);
      } else if (event.key.toLowerCase() === 'z' && event.ctrlKey) {
        if (event.shiftKey) {
          setEditBuffer((buffer) => buffer.maybeRedo());
        } else {
          setEditBuffer((buffer) => buffer.maybeUndo());
        }
      }
    };
    window.document.addEventListener('keydown', onKeyDown);

    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'p') {
        setTool(Tool.Brush);
      }
    };
    window.document.addEventListener('keyup', onKeyUp);
    return () => {
      window.document.removeEventListener('touchmove', onTouchMove);
      window.document.removeEventListener('keydown', onKeyDown);
      window.document.removeEventListener('keyup', onKeyUp);
      window.document.removeEventListener('mousemove', onMouseMove);
    };
  }, [applyToolOnPeg]);

  const onPegClick = useCallback(
    (peg: Peg, pegBead?: BeadModel) => {
      applyToolOnPeg(true, peg, pegBead);
    },
    [applyToolOnPeg]
  );

  const cursorRef = useRef<CursorHandle>(null);

  return (
    <div className={styles.container} data-testid={'c-edit'}>
      <Box>
        <Box direction={'row'} align={'center'} className={styles.section}>
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiBrush2Line size={24} />}
            onClick={() => {
              setTool(Tool.Brush);
              logEvent(analytics, 'select_tool', {
                ...eventData,
                tool: 'brush',
              });
            }}
            focusIndicator={false}
            className={`${styles.tool} ${
              tool === Tool.Brush ? styles.selected : ''
            }`}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiPaintLine size={24} />}
            onClick={() => {
              setTool(Tool.Fill);
              logEvent(analytics, 'select_tool', {
                ...eventData,
                tool: 'fill',
              });
            }}
            focusIndicator={false}
            className={`${styles.tool} ${
              tool === Tool.Fill ? styles.selected : ''
            }`}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<BsEyedropper size={24} />}
            onClick={() => {
              setTool(Tool.Dropper);
              logEvent(analytics, 'select_tool', {
                ...eventData,
                tool: 'dropper',
              });
            }}
            focusIndicator={false}
            className={`${styles.tool} ${
              tool === Tool.Dropper ? styles.selected : ''
            }`}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiEraserLine size={24} />}
            onClick={() => {
              setTool(Tool.Eraser);
              logEvent(analytics, 'select_tool', {
                ...eventData,
                tool: 'eraser',
              });
            }}
            focusIndicator={false}
            className={`${styles.tool} ${
              tool === Tool.Eraser ? styles.selected : ''
            }`}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiArrowGoBackLine size={24} />}
            onClick={() => {
              setEditBuffer((buffer) => buffer.maybeUndo());
              logEvent(analytics, 'undo', eventData);
            }}
            focusIndicator={false}
            disabled={!editBuffer.canUndo}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiArrowGoForwardLine size={24} />}
            onClick={() => {
              setEditBuffer((buffer) => buffer.maybeRedo());
              logEvent(analytics, 'redo', eventData);
            }}
            focusIndicator={false}
            disabled={!editBuffer.canRedo}
          />
          <Select
            size={'small'}
            options={pegboards}
            labelKey={(pegboard) => t(`pegboard.model.${pegboard.id}.name`)}
            value={editBuffer.current.pegboard}
            onChange={({ option }) =>
              updateBeadPattern((_) =>
                BeadPatternModel.empty(option, user!.uid)
              )
            }
            children={(pegboard) => <PegboardSelectItem pegboard={pegboard} />}
          />
          {editBuffer.current.pegboard.rotationStep && (
            <>
              <Button
                plain={true}
                margin={'xxsmall'}
                icon={<RiAnticlockwiseLine size={24} />}
                onClick={() => {
                  updateBeadPattern((beadPattern) => {
                    logEvent(
                      analytics,
                      'rotate_pegboard_left_click',
                      eventData
                    );
                    return beadPattern.rotatePegboardLeft();
                  });
                }}
                focusIndicator={false}
              />
              <Button
                plain={true}
                margin={'xxsmall'}
                icon={<RiClockwiseLine size={24} />}
                onClick={() => {
                  updateBeadPattern((beadPattern) => {
                    logEvent(
                      analytics,
                      'rotate_pegboard_right_click',
                      eventData
                    );
                    return beadPattern.rotatePegboardRight();
                  });
                }}
                focusIndicator={false}
              />
            </>
          )}
        </Box>
        <Box
          direction="row"
          justify="start"
          gap="xxsmall"
          wrap
          className={styles.beadToolContainer}>
          {filterBeads(
            sortedBeads,
            Manufacturer.h,
            editBuffer.current.pegboard.beadDiameter
          ).map((b) => {
            return (
              <div
                data-testid={`tool-bead-${b.id}`}
                key={b.id}
                className={`${styles.beadTool} ${
                  bead === b ? styles.selected : ''
                }`}
                onClick={() => {
                  setBead(b);
                  if (tool !== Tool.Fill) {
                    setTool(Tool.Brush);
                  }
                  logEvent(analytics, 'selectBead', eventData);
                }}>
                <BeadSvg bead={b} renderingOptions={toolRenderingOptions} />
              </div>
            );
          })}
        </Box>
        {editBuffer.current && (
          <>
            <div
              ref={beadPatternRef}
              className={`${styles.beadPattern} ${styles.section}`}
              onMouseEnter={updateCursor}
              onMouseMove={updateCursor}
              onMouseLeave={hideCursor}
              onTouchStart={onTouchEvent}
              onTouchEnd={onTouchEvent}
              onContextMenu={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}>
              <BeadPattern
                beadPattern={editBuffer.current}
                onPegClick={onPegClick}
                render={RenderType.svg}
                renderingOptions={renderingOptions}
              />
            </div>
            <Cursor
              bead={bead}
              ref={cursorRef}
              cursorSize={cursorSize}
              tool={tool}
            />
          </>
        )}
        <Box className={styles.section}>
          <CheckBox
            label={t('editor.public.checkbox')}
            checked={editBuffer.current.public}
            onChange={(event) =>
              updateBeadPattern((pattern) =>
                pattern.setPublic(event.target.checked)
              )
            }
          />
        </Box>
        <Box className={styles.section}>
          <TagSelect
            values={editBuffer.current!.tags}
            onChange={(v) =>
              updateBeadPattern((pattern) => pattern.setTags(v))
            }></TagSelect>
        </Box>
        <Box
          direction={'row'}
          align={'start'}
          className={styles.section}
          margin={{ bottom: 'small' }}>
          <Button
            data-testid={'save-button'}
            primary={true}
            margin={{ right: 'small' }}
            label={t('editor.save')}
            onClick={() => {
              logEvent(analytics, 'save_click', eventData);
              return save();
            }}
            disabled={!editBuffer.current.isComplete}
          />
          <Button
            label={t('editor.cancel')}
            onClick={() => {
              logEvent(analytics, 'cancel_click', eventData);
              cancel();
            }}
          />
        </Box>
      </Box>
    </div>
  );
}

interface CursorHandle {
  setVisible: (visible: boolean) => void;
  setPosition: (position: [number, number] | undefined) => void;
}

interface CursorProps {
  bead: BeadModel;
  cursorSize: number;
  tool: Tool;
}

function _Cursor(
  { bead, cursorSize, tool }: CursorProps,
  ref: Ref<CursorHandle>
) {
  const toolRenderingOptions = useBeadPatternRenderingOptions({});
  const [cursorPosition, setCursorPosition] = useState<
    [number, number] | undefined
  >();
  const [cursorVisible, setCursorVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    setVisible: (visible: boolean) => {
      setCursorVisible(visible);
    },
    setPosition: (position: [number, number] | undefined) => {
      setCursorPosition(position);
    },
  }));

  return (
    <>
      {cursorVisible && cursorPosition && (
        <div
          className={styles.cursor}
          style={{
            width: cursorSize,
            height: cursorSize,
            left: cursorPosition[0],
            top: cursorPosition[1],
          }}>
          {(tool === Tool.Brush || tool === Tool.Fill) && (
            <BeadSvg bead={bead} renderingOptions={toolRenderingOptions} />
          )}
          {tool === Tool.Eraser && <RiEraserLine size={cursorSize / 2} />}
          {/* offset the dropper icon as its hotspot is on the bottom right */}
          {tool === Tool.Dropper && (
            <BsEyedropper
              size={cursorSize / 2}
              style={{ marginLeft: `${cursorSize / 2}px` }}
            />
          )}
        </div>
      )}
    </>
  );
}

const Cursor = forwardRef(_Cursor);

export function EditBeadPattern() {
  const pathParams = useEditPathParams();
  const itemService = useContext(ApplicationContext).itemService;
  const [beadPattern, setBeadPattern] = useState<BeadPatternModel | undefined>(
    undefined
  );
  const { data: user } = useUser();
  useEffect(() => {
    itemService.getItem(pathParams.id!).then((item) => {
      if (pathParams.action === 'copy') {
        item = item?.copyForCurrentUser(user?.uid!);
      }
      setBeadPattern(item as BeadPatternModel);
    });
  }, [pathParams.id, itemService, pathParams.action, user?.uid]);
  return <>{beadPattern && <Edit beadPattern={beadPattern} />}</>;
}

export function NewBeadPattern() {
  const { data: user } = useUser();

  return <Edit beadPattern={BeadPatternModel.empty(pegboards[0], user!.uid)} />;
}
