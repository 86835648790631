import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BeadPattern as BeadPatternModel } from '../../model/bead_pattern';
import { fusePathForItem, useFusePathParams } from '../../routes/routes';
import BeadPattern from '../bead_pattern';
import { ItemHandle } from '../../common/item/item_handle';
import { SvgData } from '../../common/svg_image_renderer/renderer/renderer';
import { RiArrowGoBackLine, RiDownload2Line, RiFireLine } from 'react-icons/ri';
import styles from './styles.module.scss';
import { Box, Button } from 'grommet';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { viewPathForItem } from '../../common/routes/routes';
import ApplicationContext from '../../common/application/context';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';
import { downloadCanvasAsPng } from '../../common/svg_image_renderer/renderer/utils';
import { useBeadPatternRenderingOptions } from '../../preferences/preferences';

export default function FuseBeadPattern() {
  const renderingOptions = useBeadPatternRenderingOptions({
    interactive: false,
    renderBeadOutline: false,
    renderEmptyPegs: false,
  });
  const pathParams = useFusePathParams();
  const itemService = useContext(ApplicationContext).itemService;
  const [beadPattern, setBeadPattern] = useState<BeadPatternModel | undefined>(
    undefined
  );
  const [svgData, setSvgData] = useState<SvgData | undefined>(undefined);
  const [itemHandle, setItemHandle] = useState<ItemHandle | undefined>(
    undefined
  );
  const [melting, setMelting] = useState<boolean>(false);
  const [meltingStep, setMeltingStep] = useState<number>(0);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const meltIconRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const analytics = useAnalytics();

  const eventData = {
    item_id: pathParams.id!,
  };

  useEffect(() => {
    function isTouchingMeltingIcon(event: TouchEvent): boolean {
      let target = window.document.elementFromPoint(
        event.touches[0].clientX,
        event.touches[0].clientY
      );
      let element = target;
      while (element != null) {
        if (element === meltIconRef.current) {
          return true;
        }
        element = element.parentElement;
      }
      return false;
    }

    function onToucheMove(event: TouchEvent) {
      if (!isTouchingMeltingIcon(event)) {
        setMelting(false);
      }
    }

    function onTouchStart(event: TouchEvent) {
      if (isTouchingMeltingIcon(event)) {
        setMelting(true);
      }
    }

    function onTouchEnd(event: TouchEvent) {
      setMelting(false);
    }

    window.document.addEventListener('touchmove', onToucheMove);
    window.document.addEventListener('touchstart', onTouchStart);
    window.document.addEventListener('touchend', onTouchEnd);

    return () => {
      window.document.removeEventListener('touchmove', onToucheMove);
      window.document.removeEventListener('touchstart', onTouchStart);
      window.document.removeEventListener('touchend', onTouchEnd);
    };
  });

  useEffect(() => {
    itemService.getItem(pathParams.id!).then((item) => {
      setBeadPattern(item as BeadPatternModel);
    });
  }, [itemService, pathParams.id]);

  const onItemHandleChange = useCallback((itemHandle: ItemHandle) => {
    setItemHandle(itemHandle);
  }, []);

  const isReady = itemHandle?.isReady();
  useEffect(() => {
    if (isReady) {
      itemHandle!.getSvgData().then((data) => {
        setSvgData(data);

        const image = new Image();
        const canvas = canvasRef.current!;
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;

          canvas.getContext('2d')?.drawImage(image, 0, 0);
        };
        image.src = data!.dataUri;
      });
    }
  }, [isReady, itemHandle]);

  const melt = useCallback((iteration: number) => {
    if (iteration > 75) {
      return;
    }
    function getPixel(
      x: number,
      y: number,
      imageData: ImageData
    ): [r: number, g: number, b: number, alpha: number] {
      const offset = (y * imageData.width + x) * 4;
      return [
        imageData.data[offset],
        imageData.data[offset + 1],
        imageData.data[offset + 2],
        imageData.data[offset + 3],
      ];
    }

    function paint(
      x: number,
      y: number,
      color: [number, number, number, number],
      imageData: ImageData,
      target: Uint8ClampedArray
    ) {
      const offset = (y * imageData.width + x) * 4;
      for (var i = 0; i < 4; i++) {
        target[offset + i] = color[i];
      }
    }

    const canvas = canvasRef.current!;
    const context = canvas.getContext('2d')!;

    // On the very first step remove the anti-aliasing transparent pixels
    // but also make 5 melting steps so that the beads don't visually shrink
    const numberOfMeltingStepsInIteration = iteration > 0 ? 1 : 5;
    for (let meltIx = 0; meltIx < numberOfMeltingStepsInIteration; meltIx++) {
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

      if (iteration === 0 && meltIx === 0) {
        // Remove anti-aliasing transparent pixels
        for (var i = 0; i < imageData.data.length; i += 4) {
          // If backgroung color skip
          if (
            imageData.data[i] === 0 &&
            imageData.data[i + 1] === 0 &&
            imageData.data[i + 2] === 0 &&
            imageData.data[i + 3] === 0
          )
            continue;
          // If transparent pixel, make it to background
          if (imageData.data[i + 3] < 255) {
            imageData.data[i] = 0;
            imageData.data[i + 1] = 0;
            imageData.data[i + 2] = 0;
            imageData.data[i + 3] = 0;
          }
        }
      }

      const copy = new Uint8ClampedArray(imageData.data);

      const meltAlphaStep = 31;

      for (var x = 1; x < imageData.width - 1; x++) {
        for (var y = 1; y < imageData.height - 1; y++) {
          const [r, g, b, a] = getPixel(x, y, imageData);

          if (a === 255) {
            continue;
          }

          // Ideally we would check if any of the neighbours has the exact same color
          // at alpha 255, so we're sure we're further increasing visibility of the
          // right thing. Unfortunately, when below something is painted, it has
          // different RGB value (but same alpha) when it's read back. No idea why.
          if (a > 0 && a % meltAlphaStep === 0) {
            paint(
              x,
              y,
              [r, g, b, Math.min(a + meltAlphaStep, 255)],
              imageData,
              copy
            );
            continue;
          }

          const neighbouringColors: Array<[number, number, number, number]> =
            [];
          for (var ix = -1; ix <= 1; ix++) {
            for (var iy = -1; iy <= 1; iy++) {
              if (ix === 0 && iy === 0) continue;
              const [nr, ng, nb, na] = getPixel(x + ix, y + iy, imageData);
              if (na < 255) continue;
              neighbouringColors.push([nr, ng, nb, na]);
            }
          }

          if (neighbouringColors.length > 0) {
            const [nr, ng, nb] =
              neighbouringColors[
                Math.floor(Math.random() * neighbouringColors.length)
              ];
            paint(x, y, [nr, ng, nb, meltAlphaStep], imageData, copy);
          }
        }
      }
      imageData.data.set(copy);
      context.putImageData(imageData, 0, 0);
    }
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (melting) {
      interval = setInterval(() => {
        melt(meltingStep);
        setMeltingStep(meltingStep + 1);
      }, 50);
    }
    return () => clearInterval(interval);
  }, [meltingStep, melting, melt]);

  return (
    <>
      <Helmet>
        <title>{`${t('app.title')} - ${t(
          'view.pageTitlePrefix'
        )} ${pathParams.id!}`}</title>
        <link
          rel="canonical"
          href={`${window.location.protocol}//${
            window.location.host
          }${fusePathForItem(pathParams.id!)}`}
        />
      </Helmet>
      <Box direction="column" align="center" flex={{ shrink: 0 }}>
        <Box direction={'row'} style={{ flexShrink: 0 }} alignSelf={'start'}>
          <Button
            icon={<RiArrowGoBackLine size={24} />}
            onClick={() => {
              navigate(viewPathForItem(pathParams.id!));
            }}
          />
          <Button
            icon={<RiDownload2Line size={24} />}
            onClick={() => {
              logEvent(analytics, 'download_fused_pattern_click', eventData);
              downloadCanvasAsPng(
                canvasRef.current!,
                `pattern_${pathParams.id!}_fused.png`
              );
            }}
          />
        </Box>
        <div
          className={styles.item}
          style={{ backgroundColor: svgData ? '#eeeeee' : 'none' }}>
          {beadPattern && !svgData && (
            <BeadPattern
              beadPattern={beadPattern}
              ref={onItemHandleChange}
              renderingOptions={renderingOptions}
            />
          )}
          <canvas
            ref={canvasRef}
            style={{ display: svgData ? 'block' : 'hidden' }}></canvas>
        </div>
        {svgData && (
          <Box
            direction="column"
            className={styles.meltIcon}
            align="center"
            ref={meltIconRef}
            onContextMenu={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}>
            <RiFireLine
              onMouseDown={() => setMelting(true)}
              onMouseUp={() => setMelting(false)}
              onMouseOut={() => setMelting(false)}
              color={melting ? 'red' : 'orange'}
              size={64}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
