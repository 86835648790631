import { useMemo } from 'react';
import BeadPatternRenderingOptions from '../bead_pattern/bead_pattern_rendering_options';
import {
  BoundFloatPreferenceValueType,
  Preference,
  PreferenceType,
  PreferenceValueType,
} from '../common/preferences/preferences';
import usePreferenceValue from '../common/preferences/preferences_hook';

const beadInnerRadiusRatio = new Preference<number>(
  'bead_pattern_bead_inner_radius_ratio',
  PreferenceType.rendering,
  new BoundFloatPreferenceValueType(
    0,
    0.95,
    0.05,
    (value) => `${(value * 100).toFixed(0)}%`
  ),
  0.3
);

const beadRenderInnerShadow = new Preference<boolean>(
  'bead_pattern_bead_render_inner_shadow',
  PreferenceType.rendering,
  PreferenceValueType.boolean,
  false
);

export const beadPatternPreferencesList: Set<Preference<any>> = new Set([
  beadInnerRadiusRatio,
  beadRenderInnerShadow,
]);

export function useBeadPatternRenderingOptions({
  interactive = false,
  renderBeadOutline = true,
  renderEmptyPegs = true,
}: {
  interactive?: boolean;
  renderBeadOutline?: boolean;
  renderEmptyPegs?: boolean;
}): BeadPatternRenderingOptions {
  const innerRadiusRatio = usePreferenceValue(beadInnerRadiusRatio);
  const renderInnerShadow = usePreferenceValue(beadRenderInnerShadow);

  const memoized = useMemo(
    () =>
      new BeadPatternRenderingOptions(
        interactive,
        renderBeadOutline,
        renderEmptyPegs,
        innerRadiusRatio,
        renderInnerShadow
      ),
    [
      interactive,
      renderBeadOutline,
      renderEmptyPegs,
      innerRadiusRatio,
      renderInnerShadow,
    ]
  );
  return memoized;
}
