import { Item } from '../../model/item';
import Renderer, { SvgData } from './renderer';

export default class SvgAsPngRenderer implements Renderer {
  saveAsPng(
    item: Item<any>,
    svg: SVGSVGElement,
    fileName: string,
    scale: number
  ): void {
    import('save-svg-as-png').then((svgAsPng) => {
      svgAsPng.saveSvgAsPng(svg, fileName, { scale: scale });
    });
  }

  saveAsPdf(item: Item<any>, svg: SVGSVGElement, fileName: string): void {
    Promise.all([import('jspdf')]).then(async ([jspdf]) => {
      const doc = new jspdf.jsPDF({
        format: 'A4',
        orientation: 'portrait',
        unit: 'mm',
      });
      const imageData = await this.toPngUri(item, svg, 1);
      console.log('imageData', imageData);
      doc.addImage({
        imageData: imageData,
        format: 'PNG',
        x: 20,
        y: 20,
        width: item.widthMM,
        height: item.heightMM,
      });
      doc.save(fileName);
    });
  }

  toPngUri(
    item: Item<any>,
    svg: SVGSVGElement,
    scale: number
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      import('save-svg-as-png').then((svgAsPng) => {
        svgAsPng.svgAsPngUri(svg, { scale: scale }, (url: string) => {
          resolve(url);
        });
      });
    });
  }

  toSvg(svg: SVGSVGElement, scale: number): Promise<SvgData> {
    return new Promise<SvgData>((resolve, reject) => {
      import('save-svg-as-png').then((svgAsPng) => {
        svgAsPng.svgAsDataUri(
          svg,
          { scale: scale },
          (data: string, width: number, height: number) => {
            return resolve({
              dataUri: data,
              width: width,
              height: height,
            });
          }
        );
      });
    });
  }
}
