import { useUserInfo } from '../firebase/user_hooks';

import {
  useContext,
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import ApplicationContext from '../application/context';
import { Preference } from './preferences';

import UserInfo from '../user/user_info';

export class PreferenceValue<T> {
  constructor(
    public readonly preference: Preference<T>,
    public readonly value: T
  ) {}
}

interface PreferenceValues {
  getPreferenceValue<T>(preference: Preference<T>): T;
  setPreferenceValue<T>(preference: Preference<T>, value: T): void;
  get preferenceValues(): PreferenceValue<any>[];
}

export const PreferencesContext = createContext<PreferenceValues>(undefined!);

function persistPreferenceValue<T>(
  preference: Preference<T>,
  value: T,
  userInfo: UserInfo | undefined
): void {
  if (userInfo?.userPreferences) {
    userInfo.userPreferences.setUserPreference(preference.id, value);
  } else {
    localStorage.setItem(preference.id, JSON.stringify(value));
  }
}

function getLocalStoreValue<T>(preference: Preference<T>): T | undefined {
  const value = localStorage.getItem(preference.id);
  if (value === null) {
    return undefined;
  }
  return JSON.parse(value);
}

function readPersistedPreferenceValue<T>(
  preference: Preference<T>,
  userInfo: UserInfo | undefined
): T {
  return (
    userInfo?.userPreferences?.getUserPreference(preference.id) ??
    getLocalStoreValue(preference) ??
    preference.defaultValue
  );
}

export function PreferencesContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const [preferenceValues, setPreferenceValues] =
    useState<PreferenceValue<any>[]>();
  const userInfo = useUserInfo();
  const application = useContext(ApplicationContext);

  function getPreferenceValue<T>(preference: Preference<T>): T {
    return preferenceValues?.find((v) => v.preference.id === preference.id)
      ?.value;
  }

  function setPreferenceValue<T>(preference: Preference<T>, value: T) {
    const newPreferences = [...(preferenceValues ?? [])];
    const index = newPreferences.findIndex(
      (v) => v.preference.id === preference.id
    );
    if (index === -1) {
      newPreferences.push(new PreferenceValue(preference, value));
    } else {
      newPreferences[index] = new PreferenceValue(preference, value);
    }
    persistPreferenceValue(preference, value, userInfo);
    setPreferenceValues(newPreferences);
  }

  useEffect(() => {
    const newPreferences = Array.from(application.preferences).map(
      (p) => new PreferenceValue(p, readPersistedPreferenceValue(p, userInfo))
    );
    setPreferenceValues(newPreferences);
  }, [userInfo, application]);

  return (
    <PreferencesContext.Provider
      value={{
        getPreferenceValue: getPreferenceValue,
        setPreferenceValue: setPreferenceValue,
        preferenceValues: preferenceValues ?? [],
      }}>
      {preferenceValues && children}
    </PreferencesContext.Provider>
  );
}

export default function usePreferenceValue<T>(Preference: Preference<T>): T {
  const { getPreferenceValue } = useContext(PreferencesContext);
  return getPreferenceValue(Preference);
}
