import Experiment from '../experiments/experiment';

export default interface UserInfo {
  get isAdmin(): boolean;
  isInExperiment(experiment: Experiment): boolean;
  get userPreferences(): UserPreferences | undefined;
}

export interface UserPreferences {
  getUserPreference<T>(key: string): T | undefined;
  setUserPreference<T>(key: string, value: T): void;
}

class AnonymousUserInfo implements UserInfo {
  get isAdmin(): boolean {
    return false;
  }

  isInExperiment(experiment: Experiment): boolean {
    return false;
  }

  get userPreferences(): UserPreferences | undefined {
    return undefined;
  }
}

export const anonymousUserInfo = new AnonymousUserInfo();
