export enum PreferenceType {
  rendering,
}

export class PreferenceValueType<T> {
  static readonly boolean = new PreferenceValueType<boolean>();

  private get(): T | null {
    return null;
  }
}

export class BoundFloatPreferenceValueType extends PreferenceValueType<number> {
  constructor(
    public readonly lowerBound: number,
    public readonly upperBound: number,
    public readonly stepSize: number,
    public readonly renderValue: (value: number) => String
  ) {
    super();
  }
}

export class Preference<T> {
  constructor(
    public readonly id: string,
    public readonly type: PreferenceType,
    public readonly valueType: PreferenceValueType<T>,
    public readonly defaultValue: T
  ) {}
}
