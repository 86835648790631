import { BeadPattern as BeadPatternModel } from '../model/bead_pattern';
import React, { forwardRef, memo, Ref, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

//import { svgStyles } from '../svg/styles';
import { ItemHandle } from '../common/item/item_handle';
import SvgImgRenderer, {
  RenderType,
} from '../common/svg_image_renderer/svg_img_renderer';
import { Peg as PegModel } from '../model/pegboard';
import { Peg } from './peg';
import { svgStyles } from '../svg/styles';
import { Bead as BeadModel } from '../model/bead';
import ApplicationContext from '../common/application/context';
import Application from '../common/application/application';
import BeadPatternRenderingOptions from './bead_pattern_rendering_options';
import { useBeadPatternRenderingOptions } from '../preferences/preferences';

interface BeadPatternProps {
  beadPattern: BeadPatternModel;
  onPegClick?: (peg: PegModel, bead?: BeadModel) => void;
  render?: RenderType;
  renderingOptions?: BeadPatternRenderingOptions;
}

function _BeadPattern(
  {
    beadPattern,
    onPegClick = undefined,
    render,
    renderingOptions,
  }: BeadPatternProps,
  ref: Ref<ItemHandle>
) {
  const application = useContext<Application>(ApplicationContext);
  const defaultRenderingOptions = useBeadPatternRenderingOptions({});

  const svgMaxSize = 800;
  let svgWidth = svgMaxSize;
  let svgHeight = svgMaxSize;
  if (beadPattern.pegboard.ratio > 1) {
    svgHeight = svgMaxSize / beadPattern.pegboard.ratio;
  } else if (beadPattern.pegboard.ratio < 1) {
    svgWidth = svgMaxSize * beadPattern.pegboard.ratio;
  }
  const viewBox = `0 0 ${beadPattern.pegboard.width} ${beadPattern.pegboard.height}`;

  const [svgIdPrefix] = useState(uuidv4());

  const pegs = [];
  for (let ix = 0; ix < beadPattern.pegboard.pegs.length; ix++) {
    const peg = beadPattern.pegboard.pegs[ix];
    pegs.push(
      <Peg
        ix={ix}
        key={`${peg.x},${peg.y}`}
        peg={peg}
        bead={beadPattern.getBead(peg)}
        beadDiameter={beadPattern.pegboard.beadDiameter}
        onPegClick={onPegClick}
        svgIdPrefix={svgIdPrefix}
        renderingOptions={renderingOptions ?? defaultRenderingOptions}
      />
    );
  }

  return (
    <SvgImgRenderer
      ref={ref}
      item={beadPattern}
      render={render ?? application.defaultRenderType}
      fileNamePrefix="pattern"
      viewBox={viewBox}
      width={svgWidth}
      height={svgHeight}>
      {svgStyles(svgIdPrefix)}
      {pegs}
    </SvgImgRenderer>
  );
}

export const BeadPattern = memo(forwardRef(_BeadPattern));
export default BeadPattern;
