import { Box, Grommet, ThemeType } from 'grommet';
import { Suspense, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Navigate,
  Route,
  RouteProps,
  Routes,
} from 'react-router-dom';
import { FirebaseAppProvider, useSigninCheck } from 'reactfire';
import AdminCheck from '../admin/admin_check';
import ApprovalList from '../admin/approval_list';
import TagManagement from '../admin/tag_management';
import { PageViewLogger } from '../analytics/page_view_logger';
import Application from '../application/application';
import ApplicationContext from '../application/context';
import ApplicationHeader from '../application_header/application_header';
import { ErrorBoundary } from '../error_boundary/error_boundary';
import { GlobalErrorBoundary } from '../error_boundary/global_error_boundary';
import { firebaseConfig } from '../firebase/config';
import FirebaseEmulatorConfig from '../firebase/emulator_config';
import { FirebaseContextProviders } from '../firebase/firebase_context_providers';
import { UserContextProviders } from '../firebase/user_hooks';
import Impressum from '../impressum/impressum';
import Login from '../login/login';
import Overview from '../overview/overview';
import {
  approvalPath,
  editPath,
  impressumPath,
  loginPath,
  loginPathForRedirect,
  newPath,
  overviewPath,
  tagsPath,
  viewPath,
} from '../routes/routes';
import View from '../view/view';
import { PreferencesContextProvider } from '../preferences/preferences_hook';

interface AppInternalProps {
  help: React.ReactElement;
  theme?: ThemeType;
  additionalRoutes?: Array<RouteProps>;
}

function AppInternal({ help, theme, additionalRoutes = [] }: AppInternalProps) {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const itemService = useContext(ApplicationContext).itemService;

  return (
    <>
      <Helmet>
        <title>{t('app.title')}</title>
        <meta name={'keywords'} content={t('app.keywords') ?? undefined} />
        <meta
          name={'description'}
          content={t('app.description') ?? undefined}
        />
      </Helmet>
      <Grommet
        theme={theme}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <BrowserRouter>
          <ApplicationHeader help={help} />
          <Box fill overflow={'auto'} data-testid={'main-content'}>
            <Box align={'center'} margin={{ left: '8px', right: '8px' }}>
              <Routes>
                <Route path={'/'} element={<Navigate to={overviewPath} />} />
                <Route path={overviewPath} element={<Overview />} />
                <Route
                  path={approvalPath}
                  element={
                    <AdminCheck
                      fallback={
                        <Navigate to={loginPathForRedirect(approvalPath)} />
                      }>
                      <ApprovalList />
                    </AdminCheck>
                  }
                />
                <Route
                  path={tagsPath}
                  element={
                    <AdminCheck
                      fallback={
                        <Navigate to={loginPathForRedirect(tagsPath)} />
                      }>
                      <TagManagement />
                    </AdminCheck>
                  }
                />
                <Route
                  path={viewPath}
                  element={
                    <ErrorBoundary>
                      <View />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path={newPath}
                  element={
                    signInCheckResult.signedIn ? (
                      itemService.getItemViewNew()
                    ) : (
                      <Navigate to={loginPathForRedirect(newPath)} />
                    )
                  }
                />
                <Route
                  path={editPath}
                  element={
                    signInCheckResult.signedIn ? (
                      <ErrorBoundary>
                        {itemService.getItemViewEdit()}
                      </ErrorBoundary>
                    ) : (
                      <Navigate to={loginPathForRedirect(overviewPath)} />
                    )
                  }
                />
                <Route path={loginPath} element={<Login />} />
                <Route path={impressumPath} element={<Impressum />} />
                {additionalRoutes.map((route) => (
                  <Route {...route} key={route.path} />
                ))}
              </Routes>
            </Box>
          </Box>
          <PageViewLogger />
        </BrowserRouter>
      </Grommet>
    </>
  );
}

interface AppProps extends AppInternalProps {
  application: Application;
}

function App({ help, theme, application, additionalRoutes = [] }: AppProps) {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<p>{t('app.loading')}</p>}>
      <FirebaseAppProvider
        firebaseConfig={firebaseConfig(application.additionalAuthDomains)}
        suspense={true}>
        <FirebaseEmulatorConfig>
          <FirebaseContextProviders>
            <GlobalErrorBoundary>
              <ApplicationContext.Provider value={application}>
                <UserContextProviders>
                  <PreferencesContextProvider>
                    <AppInternal
                      help={help}
                      theme={theme}
                      additionalRoutes={additionalRoutes}
                    />
                  </PreferencesContextProvider>
                </UserContextProviders>
              </ApplicationContext.Provider>
            </GlobalErrorBoundary>
          </FirebaseContextProviders>
        </FirebaseEmulatorConfig>
      </FirebaseAppProvider>
    </Suspense>
  );
}

export default App;
