import { DropButton } from 'grommet';
import { RiSettings3Line } from 'react-icons/ri';
import PreferencesDialog from './preferences_dialog';
import { PreferenceType } from '../preferences';
import { useContext } from 'react';
import { PreferencesContext } from '../preferences_hook';

interface PreferencesButtonProps {
  preferenceTypes?: PreferenceType[];
}

function PreferencesButton({ preferenceTypes }: PreferencesButtonProps) {
  const { preferenceValues } = useContext(PreferencesContext);
  const hasAnyPreferences = preferenceValues.some(
    (v) =>
      preferenceTypes == null || preferenceTypes.includes(v.preference.type)
  );
  return (
    hasAnyPreferences && (
      <DropButton
        color={'brand'}
        icon={<RiSettings3Line size={16} />}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropContent={<PreferencesDialog preferenceTypes={preferenceTypes} />}
      />
    )
  );
}

export default PreferencesButton;
