import { useContext } from 'react';
import { Box, CheckBox, RangeInput } from 'grommet';
import { PreferencesContext, PreferenceValue } from '../preferences_hook';
import {
  BoundFloatPreferenceValueType,
  Preference,
  PreferenceType,
  PreferenceValueType,
} from '../preferences';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'reactfire';
import { Analytics, logEvent } from 'firebase/analytics';

interface PreferencesDialogProps {
  preferenceTypes?: PreferenceType[];
}

function renderControl(
  preferenceValue: PreferenceValue<any>,
  setPreferenceValue: <T>(preference: Preference<T>, value: T) => void,
  analytics: Analytics
) {
  const logChange = (value: any) =>
    logEvent(analytics, 'preference_change', {
      preference: preferenceValue.preference.id,
      value: value,
    });
  if (preferenceValue.preference.valueType === PreferenceValueType.boolean) {
    return (
      <CheckBox
        checked={preferenceValue.value}
        onChange={(e) => {
          setPreferenceValue(preferenceValue.preference, e.target.checked);
          logChange(e.target.checked);
        }}
      />
    );
  } else if (
    preferenceValue.preference.valueType instanceof
    BoundFloatPreferenceValueType
  ) {
    return (
      <Box direction={'row'}>
        <RangeInput
          min={preferenceValue.preference.valueType.lowerBound}
          max={preferenceValue.preference.valueType.upperBound}
          step={preferenceValue.preference.valueType.stepSize}
          value={preferenceValue.value}
          onChange={(e) => {
            setPreferenceValue(preferenceValue.preference, e.target.value);
            logChange(e.target.value);
          }}
        />
        &nbsp;
        {preferenceValue.preference.valueType.renderValue(
          preferenceValue.value
        )}
      </Box>
    );
  }
  return <></>;
}

function PreferencesDialog({ preferenceTypes }: PreferencesDialogProps) {
  const preferenceValues = useContext(PreferencesContext);
  const { t } = useTranslation();
  const analytics = useAnalytics();

  return (
    <Box pad="medium" gap="small">
      {preferenceValues.preferenceValues
        .filter(
          (v) => preferenceTypes == null || v.preference.type in preferenceTypes
        )
        .map((v) => (
          <Box
            key={v.preference.id}
            direction="column"
            align={'stretch'}
            gap="small">
            <label>{t(`preferences.${v.preference.id}.label`)}</label>
            {renderControl(v, preferenceValues.setPreferenceValue, analytics)}
          </Box>
        ))}
    </Box>
  );
}

export default PreferencesDialog;
